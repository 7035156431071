<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      light
      max-width="900px"
    >
      <v-card>
        <v-card-title>
          <h2 class="text-center font-weight-medium align-center mt-3 mb-3">
            Upload de Arquivos
          </h2>
        </v-card-title>
        <v-card-text>
          <v-row
            justify="center"
          >
            <v-col
              cols="12"
            >
              <v-file-input
                v-model="files"
                multiple
                color="purple"
                show-size
                counter
                label="Selecione os Arquivos"
              >
                <template v-slot:selection="{ text }">
                  <v-chip
                    color="purple"
                    dark
                    label
                  >
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>
            <v-col
              cols="12"
            >
              <h4 class="text-center font-weight-medium align-center mt-3 mb-3">
                Selecione o Diretório
              </h4>
              <node
                :nodes="tree"
              />
            </v-col>
            <!-- <v-col
              v-if="filesFail.length"
              class="text-center"
              cols="10"
            >
              <v-alert
                outlined
                color="warning"
                text
              >
                <h3 class="font-weight-medium align-center mt-3 mb-3">
                  Aviso! Os seguintes arquivos possuem mais de 100MB e por isso irão falhar no upload:
                </h3>

                <h3 class="font-weight-medium text-justify mt-3 mb-3">
                  <ul>
                    <li
                      v-for="(element, i) in filesFail"
                      :key="i"
                    >
                      {{ element.name }}
                      {{ tamanhoArquivo(element.size) }}
                    </li>
                  </ul>
                </h3>
              </v-alert>
            </v-col> -->
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#E0115F"
            text
            @click="updateDialog(!dialog)"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-if="getDirectories && files.length"
            color="#4CAF50"
            text
            @click="send()"
          >
            Enviar
          </v-btn>
        </v-card-actions>
        <v-snackbar
          v-model="snackbar"
          multi-line
          color="#0277BD"
          :timeout="timeout"
        >
          <v-row
            justify="center"
          >
            <v-col
              cols="12"
            >
              <h3 class="font-weight-medium text-center align-center mt-3 mb-3">
                {{ snackbarText }}
              </h3>
              <v-progress-linear
                v-if="uploading"
                :value="percentCompleted"
                stream
                rounded
                color="#4CAF50"
                height="25"
              >
                <template v-slot="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
            </v-col>
          </v-row>
        </v-snackbar>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import axiosSYNC from '../../../../../axios-SYNC'
  import filesize from 'filesize'
  export default {
    name: 'UploadFiles',
    directives: {
    },
    components: {
      Node: () => import('./node'),
    },
    props: {
      localDialog: {
        type: Boolean,
        required: true,
      },
      bucketName: {
        type: String,
        required: true,
      },
      tree: {
        type: Array,
        required: true,
      },
    },
    data: () => {
      return {
        percentCompleted: 0,
        files: [],
        uploading: false,
        snackbar: false,
        snackbarText: '',
        timeout: -1,
      }
    },
    computed: {
      ...mapGetters('files', ['fileList', 'dialogState', 'getDirectories']),
      dialog: {
        get: function () {
          return this.localDialog
        },
        set: function (newValue) {
          this.localDialog = newValue
          this.updateDialog(newValue)
        },
      },
      filesFail () {
        return this.tooBig()
      },
    },
    methods: {
      ...mapActions('files', ['updateDialog', 'fileRequest']),
      async send () {
        const formData = new FormData()
        for (const index in this.files) {
          formData.append('files[' + index + ']', this.files[index])
        }
        let path = this.getDirectories.replace(this.bucketName + '/', '')
        if (this.getDirectories.includes(this.bucketName + '/')) {
          path = this.getDirectories.replace(this.bucketName + '/', '')
        } else {
          path = ''
        }
        this.snackbar = true
        this.uploading = true
        this.snackbarText = 'Aguarde, realizando o upload dos arquivos...'
        const config = {
          responseType: 'arraybuffer',
          onUploadProgress: function (progressEvent) {
            this.percentCompleted = progressEvent.loaded * 100 / progressEvent.total
          }.bind(this),
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          params: {
            bucket: this.bucketName,
            location: path,
          },
        }
        await axiosSYNC.post('upload/backup', formData, config)
          .then((response) => {
            this.timeout = 8000
            this.uploading = false
            this.snackbarText = 'O upload de ' + this.files.length + ' arquivo(s) foi concluido!'
            this.files.splice(0, this.files.length)
            setTimeout(() => {
              this.updateDialog(false)
              this.timeout = -1
            }, 2000)
          })
          .catch((err) => {
            this.timeout = 800
            this.uploading = false
            this.snackbarText = 'O upload falhou' + err
            console.error('FAILURE!!', err)
            this.updateDialog(false)
          })
      },
      tooBig () {
        const arr = []
        this.files.map((entry) => {
          if (entry.size > 104857600) {
            arr.push(entry)
          }
        })
        return arr
      },
      tamanhoArquivo (val) {
        return filesize(val, { base: 10 })
      },
    },
  }
</script>
